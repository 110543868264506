<template>
  <div>
    <v-layout v-resize="onResize" column style="">
      <v-dialog v-model="smsConfirmationDialog" persistent width="430" :overlay-opacity="0.8"
        :overlay-color="overlayColor" content-class="plain-dialog">
        <v-card flat>
          <v-card-text class="pa-7">
            <div class="modal-title" style="font-size: 18px; text-align: center; line-height: 30px">
              Are you sure you want to send the text?
              <b>This action is not reversible.</b>
            </div>
            <div class="modal-actions">
              <a @click="performSendText()" class="btn mt-3">Send Message</a>
              <a @click="cancelConfirmationModal()" class="btn secondary mt-3">Cancel</a>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model="smsDialog" persistent width="700" :overlay-opacity="0.8" :overlay-color="overlayColor"
        content-class="plain-dialog">
        <v-card flat>
          <v-card-text class="pa-7">
            <div class="modal-title">
              <h4>Edit Message</h4>
            </div>
            <div class="modal-body">
              <v-row>
                <v-col>
                  <div class="instructions">
                    <p>
                      Use the provided placeholders in your template to insert
                      client or advisor information.<br />
                    </p>
                  </div>
                  <v-textarea class="textarea sms-template" outlined name="smsTemplate" aria-required="true" auto-grow
                    v-model="smsTemplate" :backgroundColor="inputBackgroundColor" @input="$v.smsTemplate.$touch()"
                    @blur="$v.smsTemplate.$touch()" hide-details="auto" />
                  <div class="instructions">
                    <p>
                      <b>Mandatory placeholders</b>:
                      <span v-for="placeholder in mandatoryPlaceholders" :key="placeholder">
                        <strong> {{ placeholder }} </strong>
                      </span>
                    </p>
                    <p>
                      <b>Available placeholders:</b>
                      <span v-for="placeholder in generalPlaceholders" :key="placeholder">
                        <strong> {{ placeholder }} </strong>
                      </span>
                    </p>
                  </div>
                </v-col>
              </v-row>
            </div>
            <div class="modal-actions">
              <a @click="confirmSendText()" class="btn mt-3">Send Message</a>
              <a @click="cancelSMSModal()" class="btn secondary mt-3">Cancel</a>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="urlDialog" width="600" :overlay-opacity="0.8" :overlay-color="overlayColor" content-class="plain-dialog">
        <v-card flat>
          <v-card-text class="pa-7">
            <div class="modal-title">
              <h4>Transition URLs</h4>
            </div>
            <div class="modal-body">
              <div v-if="transitionUrls">
                <div v-if="transitionUrls.primaryUrl" class="url-item">
                  <div class="url-label">Primary Contact URL:</div>
                  <div class="url-content">
                    <span class="url-text">{{ transitionUrls.primaryUrl }}</span>
                    <v-btn small text color="primary" @click="copyUrl(transitionUrls.primaryUrl)">
                      <v-icon small>fas fa-copy</v-icon>
                    </v-btn>
                  </div>
                </div>
                <div v-if="transitionUrls.secondaryUrl" class="url-item mt-4">
                  <div class="url-label">Secondary Contact URL:</div>
                  <div class="url-content">
                    <span class="url-text">{{ transitionUrls.secondaryUrl }}</span>
                    <v-btn small text color="primary" @click="copyUrl(transitionUrls.secondaryUrl)">
                      <v-icon small>fas fa-copy</v-icon>
                    </v-btn>
                  </div>
                </div>
                <div v-if="!transitionUrls.primaryUrl && !transitionUrls.secondaryUrl" class="no-urls">
                  No URLs returned.
                </div>
              </div>
            </div>
            <div class="modal-actions">
              <a @click="closeUrlDialog()" class="btn secondary mt-3">Close</a>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-data-table :headers="headers" :items="clients" item-key="_id" :loading="tableLoading"
        class="responsive-table household-container elevation-1" show-select  v-model="selectedItems" ref="dataTable">
        <template v-slot:top>
          <v-row>
            <v-col>
              <v-text-field outlined type="text" maxlength="256" placeholder="Search households..." v-model.trim="search"
                :backgroundColor="inputBackgroundColor" clearable class="search-input mb-2" single-line hide-details
                ref="search" @click:clear="resetItems()">
                <template v-slot:append>
                  <span class="search" v-if="!search || search.length === 0">
                    <v-icon small>fas fa-search</v-icon>
                  </span>
                </template>
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field outlined type="text" maxlength="256" placeholder="Search Primary Advisor..."
                v-model.trim="searchRep" :backgroundColor="inputBackgroundColor" clearable class="search-input mb-2"
                single-line hide-details ref="searchRep" @click:clear="resetItems()">
                <template v-slot:append>
                  <span class="search" v-if="!searchRep || searchRep.length === 0">
                    <v-icon small>fas fa-search</v-icon>
                  </span>
                </template>
              </v-text-field>
            </v-col>
          </v-row>

          <v-row class="filter-action-row align-center">
            <v-col class="status-col">
              <v-select v-model="filterStatus" :items="transitionStatuses" item-text="text" item-value="value"
                :backgroundColor="inputBackgroundColor" outlined placeholder="Status" ref="statusFilter" hide-details
                dense>
                <template v-slot:prepend-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="prepend" @click="clearStatusFilter()">
                        Clear Filter
                        <a class="close">
                          <div><i class="fas fa-times"></i></div>
                        </a>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-select>
            </v-col>
            <v-col class="text-right">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    rounded
                    v-show="selectedItems.length > 0"
                    v-bind="attrs"
                    v-on="on"
                    class="batch-activator"
                    >Batch ({{ selectedItems.length }})
                    <span class="fa-icon"
                      ><i class="fas fa-ellipsis-v"></i></span
                  ></v-btn>
                </template>
                <v-list>
                  <v-list-item @click="sendBatch">Send Text</v-list-item>
                  <v-list-item @click="deleteBatch">Delete</v-list-item>
                </v-list>
              </v-menu>
              <v-btn rounded color="primary" @click="transitionClients" class="batch-activator">
                Add Transitions
                <v-icon right> fas fa-plus-circle </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </template>

        <template v-slot:item.primaryAdvisor="{ item }">
          {{ getPrimaryAdvisorName(item) }}
        </template>

        <template v-slot:item.assetsValue="{ item }">
          {{ formatNumber(item.assetsValue) }}
        </template>

        <template v-slot:item.status="{ item }">
          <span :class="headers.find((h) => h.value === 'status').classFn(item)">
            {{ headers.find((h) => h.value === 'status').labelFn(item) }}
          </span>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-menu left nudge-left="15" nudge-bottom="25" min-width="125">
            <template v-slot:activator="{ on, attrs }">
              <span class="actions" v-bind="attrs" v-on="on" :key="item">
                <v-icon small>fas fa-ellipsis-h</v-icon>
              </span>
            </template>
            <v-list class="menu">
              <div v-for="(action, index) in headers.find(
                (h) => h.value === 'actions',
              ).actions" :key="index">
                <v-list-item v-if="action.conditionFn(item)" @click="action.clickFn(item)">
                  <v-list-item-title class="menu-item-wrapper">
                    <v-icon x-small :key="item.status">
                      {{ action.iconFn(item) }}
                    </v-icon>
                    <span class="label">
                      {{ action.labelFn(item) }}
                    </span>
                  </v-list-item-title>
                </v-list-item>
                <div class="divider" v-if="action.isDivider && canShowDivider(props.item)"></div>
              </div>
            </v-list>
          </v-menu>
        </template>

        <!-- <template v-slot:expanded-item="{ item }">
          <td :colspan="headers.length + 1">
            <div class="expanded-container">
              <v-container fluid>
                <v-row class="expanded-header">
                  <v-col>First Name</v-col>
                  <v-col>Last Name</v-col>
                  <v-col>Mobile</v-col>
                  <v-col v-if="item.transitionType === 'Protocol'">Email</v-col>
                </v-row>

                <v-row class="client-details">
                  <v-col>{{ item.primaryContact.firstName }}</v-col>
                  <v-col>{{ item.primaryContact.lastName }}</v-col>
                  <v-col>{{ item.primaryContact.mobile }}</v-col>
                  <v-col v-if="item.transitionType === 'Protocol'">{{
                    item.primaryContact.email
                  }}</v-col>
                </v-row>

                <v-row class="client-details" v-if="item.secondaryContact">
                  <v-col>{{ item.secondaryContact.firstName }}</v-col>
                  <v-col>{{ item.secondaryContact.lastName }}</v-col>
                  <v-col>{{ item.secondaryContact.mobile }}</v-col>
                  <v-col v-if="item.transitionType === 'Protocol'">{{
                    item.secondaryContact.email
                  }}</v-col>
                </v-row>
              </v-container>
            </div>
          </td>
        </template> -->
      </v-data-table>
    </v-layout>
  </div>
</template>
<script>
import {
  GET_TRANSITIONING_CLIENTS,
  DELETE_TRANSITION,
  SET_WORKING_TRANSITION,
  GET_TRANSITION,
  SET_WORKING_CLIENT,
  DELETE_BATCH_TRANSITIONS,
  SEND_TRANSITION_SMS, 
  SEND_BATCH_TRANSITION_SMS,
  GET_TRANSITION_URL
} from '@/stores/actions.type';
import {
  NOTIFICATION_TYPES,
  NotificationService,
} from '@/services/notification.service';
import { PermissionService, PERMISSIONS } from '@/services/permission.service';
import {
  TRANSITION_STATUSES,
  TRANSITION_TYPES,
  MENUS,
  MESSAGE_PLACEHOLDERS,
} from '@/common/constants';
import { teamAdvisorsMapper } from '@/utils/responseMapper/mappers/teamAdvisorsMapper';
import { mapGetters } from 'vuex';

export default {
  name: 'TransitionHouseholdsTable',
  computed: {
    ...mapGetters([
      'currentCompany',
      'transitionHouseholds',
      'selectedTransitionType',
    ]),

    generalPlaceholders() {
      return MESSAGE_PLACEHOLDERS.filter(
        (placeholder) =>
          !placeholder.includes('emailLink') &&
          !placeholder.includes('statementLink'),
      ).map((placeholder) =>
        placeholder.replace(/organisation/g, 'organization'),
      );
    },

    mandatoryPlaceholders() {
      if (this.selectedTransition) {
        return this.selectedTransition.transitionType === 'Protocol'
          ? MESSAGE_PLACEHOLDERS.filter((placeholder) =>
            placeholder.includes('statementLink'),
          )
          : MESSAGE_PLACEHOLDERS.filter((placeholder) =>
            placeholder.includes('emailLink'),
          );
      } else {
        return [];
      }
    },
  },

  data() {
    return {
      selectedItems: [],
      itemsToSendBatch: [],
      selectedTransition: null,
      smsDialog: false,
      isMobile: false,
      smsConfirmationDialog: false,
      smsTemplate:
        'Dear valued client, \nWe would like to inform you that your account is in the process of transitioning. If you wish to continue your association with us, please upload your statement at [YourLinkHere]. We value your association and are here to assist you through this process.\nWarm regards,[Your Company Name]',
      search: '',
      searchRep: '',
      tableLoading: false,
      clients: [],
      filterStatus: null,
      headers: [
        {
          text: 'Household Name',
          value: 'household',
          width: '30%',
          filter: (value) => {
            if (!this.search || this.search.length === 0) return true;
            return value.match(new RegExp(this.search, 'i'));
          },
        },
        {
          text: 'Primary Advisor',
          value: 'primaryAdvisor',
          width: '20%',
          filter: (value) => {
            if (!this.searchRep || this.searchRep.length === 0) return true;
            return (
              value.personalInfo.firstName.match(
                new RegExp(this.searchRep, 'i'),
              ) ||
              value.personalInfo.lastName.match(new RegExp(this.searchRep, 'i'))
            );
          },
        },
        {
          text: 'Status',
          value: 'status',
          width: '20%',
          filter: (value) => {
            if (!this.filterStatus) return true;
            return value === this.filterStatus;
          },
          classFn: (item) => {
            let clz = item.status;
            switch (item.status) {
              case TRANSITION_STATUSES.STATEMENT_UPLOADED:
                clz = 'StatementUploaded';
                break;
              case TRANSITION_STATUSES.STATEMENT_PENDING:
                clz = 'WaitingStatement';
                break;
              case TRANSITION_STATUSES.NONSOLICIT_PENDING:
                clz = 'NonSolicitPending';
                break;
              case TRANSITION_STATUSES.DRAFT:
                clz = 'Draft';
                break;
              case TRANSITION_STATUSES.TRANSITIONED:
                clz = "Transitioned";
                break;
            }

            return `status-pill ${clz}`;
          },
          labelFn: (item) => {
            switch (item.status) {
              case TRANSITION_STATUSES.STATEMENT_UPLOADED:
                return 'Statement Uploaded';
              case TRANSITION_STATUSES.STATEMENT_PENDING:
                return 'Waiting Statement';
              case TRANSITION_STATUSES.NONSOLICIT_PENDING:
                return 'Non-Solicit Pending';
              case TRANSITION_STATUSES.EMAIL_PENDING:
                return 'Email Pending';
              case TRANSITION_STATUSES.DRAFT:
                return 'Draft';
              case TRANSITION_STATUSES.TRANSITIONED:
                return "Transitioned";
              default:
                return item.status;
            }
          },
        },
        { text: 'Household Value', value: 'assetsValue', width: '18%' },
        {
          text: 'Actions',
          value: 'actions',
          width: '12%',
          isActionColumn: true,
          sortable: false,
          align: 'end',
          actions: [
            {
              conditionFn: (item) => {
                return this.canStartOnbord(item);
              },
              clickFn: (item) => {
                this.startOnbord(item);
              },
              labelFn: () => {
                return 'Start Onbord';
              },
              iconFn: () => {
                return 'fas fa-rocket';
              },
            },
            {
              conditionFn: (item) => {
                return this.canSendText(item);
              },
              clickFn: (item) => {
                this.sendText(item);
              },
              labelFn: () => {
                return 'Send Text';
              },
              iconFn: () => {
                return 'fas fa-redo';
              },
            },
            {
              conditionFn: (item) => {
                return this.canEditTransition(item);
              },
              clickFn: (item) => {
                this.editTransition(item);
              },
              labelFn: () => {
                return 'Edit';
              },
              iconFn: () => {
                return 'fas fa-pencil';
              },
            },
            {
              conditionFn: (item) => {
                return item.status !== TRANSITION_STATUSES.DRAFT 
                  && item.status !== TRANSITION_STATUSES.STATEMENT_UPLOADED 
                  && item.status !== TRANSITION_STATUSES.NONSOLICIT_PENDING;
              },
              clickFn: (item) => {
                this.getTransitionUrl(item);
              },
              labelFn: () => {
                return 'Copy URL';
              },
              iconFn: () => {
                return 'fas fa-link';
              },
            },
            {
              conditionFn: () => {
                return true;
              },
              clickFn: (item) => {
                this.deleteTransition(item);
              },
              labelFn: () => {
                return 'Delete';
              },
              iconFn: () => {
                return 'fas fa-trash';
              },
            },
          ],
        },
      ],
      urlDialog: false,
      transitionUrls: null,
    };
  },

  created() {
    this.getTransitioningClientsList();
    this.transitionStatuses = Object.keys(TRANSITION_STATUSES).map((key) => {
      const status = TRANSITION_STATUSES[key];
      return {
        value: status,
        text: this.customDisplayFunction(status),
      };
    });
  },

  methods: {
    transitionClients() {
      this.$router
        .push({ name: MENUS.TRANSITIONS.TRANSITION_TYPE.id })
        .catch(() => { });
    },
    canSendText(item) {
      const transitioned = item.status !== TRANSITION_STATUSES.TRANSITIONED;
      if (!transitioned) {
        return false;
      }
      const contactsHaveEmail = [
        item.primaryContact,
        item.secondaryContact,
      ].every((contact) => {
        if (contact) {
          return !!contact.mobile;
        }
        return true;
      });

      return contactsHaveEmail;
    },
    confirmSendText() {
      this.smsConfirmationDialog = true;
    },
    cancelSMSModal() {
      this.smsDialog = false;
    },
    cancelConfirmationModal() {
      this.smsConfirmationDialog = false;
    },
    sendText(item) {
      if (this.currentCompany.transitionNotificationsDisabled) {
        NotificationService.alert({
          type: NOTIFICATION_TYPES.WARNING,
          title: 'Warning',
          message:
            'You have disabled transition notifications in your Company Profile. SMS will not be sent!',
          okTitle: 'Ok',
        });
      } else {
        const notificationTemplates = this.currentCompany.notificationTemplates;
        const foundTemplate =
          notificationTemplates &&
          notificationTemplates.find((template) => {
            if (item.transitionType === 'Protocol') {
              return template.templateName === 'TransitionStatement';
            }
            return (
              template.templateName === 'NonProtocolTransitionRequestEmail'
            );
          });

        if (item.status === 'Draft' && !foundTemplate) {
          NotificationService.alert(
            {
              type: NOTIFICATION_TYPES.ERROR,
              title: 'Error',
              message: 'Error getting sms template',
              okTitle: 'Ok',
            },
            'No sms template found',
          );
          return;
        }

        this.smsTemplate = foundTemplate.template;
        this.selectedTransition = item;
        this.smsDialog = true;
      }
    },
    onResize() {
      this.isMobile = window.innerWidth < 769;
    },
    performSendText() {
      const isBatch = this.itemsToSendBatch && this.itemsToSendBatch.length > 0;
      const items = isBatch ? this.itemsToSendBatch : [this.selectedTransition];

      const transitionIds = items.map(item => item._id);

      const actionType = isBatch ? SEND_BATCH_TRANSITION_SMS : SEND_TRANSITION_SMS;
      const payloadKey = isBatch ? 'transitionIds' : 'transitionId';

      this.$store
        .dispatch(actionType, {
          organisationId: this.currentCompany.id,
          [payloadKey]: isBatch ? transitionIds : transitionIds[0],
          smsPayload: this.smsTemplate,
        })
        .then(() => {
          NotificationService.alert({
            type: NOTIFICATION_TYPES.SUCEESS,
            title: 'Success',
            message: 'SMS Successfully sent!',
            okTitle: 'Ok',
          });
          if (isBatch) this.clearSelections(); 
        })
        .catch((error) => {
          NotificationService.alert(
            {
              type: NOTIFICATION_TYPES.ERROR,
              title: 'Error',
              message:
                'Error sending SMS! Check if you have all SMS templates set up in your Company Profile.',
              okTitle: 'Ok',
            },
            error,
          );
        })
        .finally(() => {
          this.smsDialog = false;
          this.smsConfirmationDialog = false;
          this.getTransitioningClientsList();
        });
    },
    clearSelections() {
      this.selectedItems = [];
      this.itemsToSendBatch = [];
    },
    prepareBatchSmsTemplate(itemsToSend) {
      const firstItem = itemsToSend[0]; // Take the first valid item to derive the SMS template
      const notificationTemplates = this.currentCompany.notificationTemplates;
      
      const foundTemplate = notificationTemplates && notificationTemplates.find(template => {
        return firstItem.transitionType === 'Protocol' 
          ? template.templateName === 'TransitionStatement' 
          : template.templateName === 'NonProtocolTransitionRequestEmail';
      });

      this.smsTemplate = foundTemplate ? foundTemplate.template : this.smsTemplate;
      this.itemsToSendBatch = itemsToSend; // Store items for later use
      this.smsDialog = true; // Open the dialog to allow for SMS template editing
    },
    sendBatch() {
      if (this.selectedItems.length === 0) return;

        // Filter the items that can actually receive texts
      const itemsToSend = this.selectedItems.filter(item => this.canSendText(item));
        
      if (itemsToSend.length === 0) {
        NotificationService.alert({
          type: NOTIFICATION_TYPES.WARNING,
          title: 'Warning',
          message: 'No valid items to send text.',
          okTitle: 'Ok',
        });
        return;
      }

        // Show the SMS template for correction before sending
      this.prepareBatchSmsTemplate(itemsToSend);
    },
    startOnbord(item) {
      this.$store
        .dispatch(GET_TRANSITION, item._id)
        .then((response) => this.reconstituteClient(response))
        .then((workingClient) => {
          return this.$store.dispatch(SET_WORKING_CLIENT, workingClient)
        })
        .then(() =>
          this.$router.push({ name: MENUS.ADVISOR.CLIENT.CLIENT_SELECTION.id }),
        )
        .catch((error) => {
          this.clients = [];
          NotificationService.alert(
            {
              type: NOTIFICATION_TYPES.ERROR,
              title: 'Error',
              message: 'Error starting OnBord flow!',
              okTitle: 'Ok',
            },
            error,
          );
        });
    },
    generateHouseholdName(item) {
      if (item.clients.length === 1) {
        const client = item.primaryContact;
        return `${client.firstName} ${client.lastName}`;
      } else if (item.clients.length >= 2) {
        const client1 = item.primaryContact;
        const client2 = item.secondaryContact;
        const lastName =
          client1.lastName === client2.lastName
            ? client1.lastName
            : `${client1.lastName} - ${client2.lastName}`;
        return `${client1.firstName} & ${client2.firstName} ${lastName}`;
      } else {
        return 'Unknown Household';
      }
    },
    getClientHeaders(item) {
      const baseHeaders = [
        { text: 'First Name', value: 'firstName' },
        { text: 'Last Name', value: 'lastName' },
        { text: 'Mobile', value: 'phone' },
      ];

      const protocolHeaders = [{ text: 'Email', value: 'email' }];

      return item.transitionType === TRANSITION_TYPES.PROTOCOL
        ? baseHeaders.concat(protocolHeaders)
        : baseHeaders;
    },
    canEditTransition(item) {
      return item.status === TRANSITION_STATUSES.DRAFT;
    },
    async editTransition(item) {
      if (!this.canEditTransition(item)) {
        return;
      }

      this.$store
        .dispatch(GET_TRANSITION, item._id)
        .then((response) => this.reconstituteTransition(response))
        .then((transition) =>
          this.$store.dispatch(SET_WORKING_TRANSITION, transition),
        )
        .then(() => {
          this.$router
            .push({
              name: MENUS.TRANSITIONS.EDIT_TRANSITION.id,
            })
            .catch((error) => {
              NotificationService.alert(
                {
                  type: NOTIFICATION_TYPES.ERROR,
                  title: 'Error',
                  message: 'Error editing transition!',
                  okTitle: 'Ok',
                },
                error,
              );
            });
        });
    },
    canStartOnbord(item) {
      const { ADD_CLIENT } = PERMISSIONS.CLIENT;

      const hasPermission = PermissionService.hasPermission(ADD_CLIENT);
      const statementUploaded =
        item.status === TRANSITION_STATUSES.STATEMENT_UPLOADED ||
        item.status === TRANSITION_STATUSES.STATEMENT_PENDING;

      return [hasPermission, statementUploaded].every((x) => x);
    },
    mapClient(client) {
      if (!client) {
        return null;
      }

      return {
        type: 'Existing',
        firstName: client.firstName,
        lastName: client.lastName,
        email: client.email,
        phone: client.mobile,
        crmId: client.crmClientId,
      };
    },
    reconstituteClient(response) {
      const primaryAdvisor = teamAdvisorsMapper({
        result: [response.primaryAdvisor],
      });

      const primaryCSA = teamAdvisorsMapper({
        result: [response.primaryCSA],
      });
      return {
        client1: this.mapClient(response.primaryContact),
        client2: this.mapClient(response.secondaryContact),
        addSecond: response.secondaryContact ? 'Y' : 'N',
        firmRoles: {
          primaryCSA: primaryCSA && primaryCSA.pop(),
          primaryAdvisor: primaryAdvisor && primaryAdvisor.pop(),
        },
        transitionId: response._id,
      };
    },
    reconstituteTransition(response) {
      const transitioningHousehold = {
        primaryContact: response.primaryContact,
        secondaryContact: response.secondaryContact,
        addSecond: response.secondaryContact ? 'Y' : 'N',
        firmRoles: {
          primaryAdvisor: response.primaryAdvisor || null,
          primaryCSA: response.primaryCSA || null,
        },
        assetsValue: response.assetsValue || null,
        transitionType: response.transitionType,
        id: response._id,
      };

      return transitioningHousehold;
    },
    doDelete(transition) {
      this.$store
        .dispatch(DELETE_TRANSITION, transition)
        .then(() => {
          NotificationService.alert({
            type: NOTIFICATION_TYPES.SUCEESS,
            title: 'Success',
            message: 'Transition Successfully deleted!',
            okTitle: 'Ok',
          });
        })
        .catch((error) => {
          NotificationService.alert(
            {
              type: NOTIFICATION_TYPES.ERROR,
              title: 'Error',
              message: 'Error deleting transition!',
              okTitle: 'Ok',
            },
            error,
          );
        })
        .finally(() => {
          this.getTransitioningClientsList();
        });
    },

    deleteTransition(item) {
      const msg = 'Are you sure you want to delete this transition?';
      NotificationService.confirm({
        type: NOTIFICATION_TYPES.WARNING,
        message: msg,
        okTitle: "Yes I'm sure",
        okMethod: () => {
          this.doDelete(item._id);
        },
      });
    },

    customDisplayFunction(item) {
      switch (item) {
        case TRANSITION_STATUSES.STATEMENT_UPLOADED:
          return 'Uploaded Statement';
        case TRANSITION_STATUSES.STATEMENT_PENDING:
          return 'Statement Pending';
        case TRANSITION_STATUSES.NONSOLICIT_PENDING:
          return 'Non-Solicit Pending';
        case TRANSITION_STATUSES.DRAFT:
          return 'Draft';
        case TRANSITION_STATUSES.TRANSITIONED:
          return "Transitioned";
        default:
          return item;
      }
    },

    clearStatusFilter() {
      this.filterStatus = null;
      this.$refs.statusFilter.blur();
    },

    resetItems() {
      this.tableLoading = true;
      this.$nextTick(() => setTimeout(() => (this.tableLoading = false)), 500);
    },

    formatNumber(value) {
      return new Intl.NumberFormat('en-US').format(value);
    },

    getPrimaryAdvisorName(item) {
      return (
        item.primaryAdvisor.personalInfo.firstName +
        ' ' +
        item.primaryAdvisor.personalInfo.lastName
      );
    },
    getHouseholdName(item) {
      const primaryFirstName = item.primaryContact.firstName;
      const primaryLastName = item.primaryContact.lastName;
      let householdName = `${primaryFirstName} ${primaryLastName}`;
      if (item.secondaryContact && item.secondaryContact.firstName) {
        const secondaryFirstName = item.secondaryContact.firstName;
        const secondaryLastName = item.secondaryContact.lastName;
        householdName = `${primaryFirstName} & ${secondaryFirstName}`;
        if (primaryLastName !== secondaryLastName) {
          householdName += ` ${primaryLastName} - ${secondaryLastName}`;
        }
      }
      return householdName;
    },
    getTransitioningClientsList() {
      this.tableLoading = true;
      this.clients = [];

      this.$store
        .dispatch(GET_TRANSITIONING_CLIENTS)
        .then((response) => {
          if (response) {
            this.clients = response.map((client) => ({
              ...client,
              household: this.getHouseholdName(client),
            }));
          } else {
            this.clients = [];
          }
        })
        .catch((error) => {
          this.clients = [];
          NotificationService.alert(
            {
              type: NOTIFICATION_TYPES.ERROR,
              title: 'Error',
              message: 'Error getting list of transitioning clients!',
              okTitle: 'Ok',
            },
            error,
          );
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
  

  // Batch processing for deleting transitions (LABEL: Batch Delete)
    deleteBatch() {
      if (this.selectedItems.length === 0) return;
      const transitionIds = this.selectedItems.map(item => item._id)

      const msg = 'Are you sure you want to delete the selected transitions?';
      NotificationService.confirm({
        type: NOTIFICATION_TYPES.WARNING,
        message: msg,
        okTitle: "Yes I'm sure",
        okMethod: () => {
          // Using Promise.all to delete transitions in batch
          this.processDelete(transitionIds)
            .then(() => {
              NotificationService.alert({
                type: NOTIFICATION_TYPES.SUCCESS,
                title: 'Success',
                message: 'Transitions successfully deleted!',
                okTitle: 'Ok',
              });
              this.selectedItems = []; 
            })
            .catch((error) => {
              console.log(error)
              NotificationService.alert({
                type: NOTIFICATION_TYPES.ERROR,
                title: 'Error',
                message: 'Error deleting transitions!',
                okTitle: 'Ok',
              }, error);
            });
        },
      });
    },

  // Helper method to delete a single item
    processDelete(transitionIds) {
      return new Promise((resolve, reject) => {
        this.$store.dispatch(DELETE_BATCH_TRANSITIONS, transitionIds)
          .then(() => {
            resolve();
          })
          .catch((error) => {
            reject(error);
          })
          .finally(() => {
            this.getTransitioningClientsList();
          });
      });
    },

    getTransitionUrl(item) {
      this.tableLoading = true;
      this.urlDialog = true;
      this.transitionUrls = null;

      this.$store.dispatch(GET_TRANSITION_URL, item._id)
        .then((response) => {
          if (response) {
            this.transitionUrls = response;
          } else {
            this.transitionUrls = { primaryUrl: null, secondaryUrl: null };
          }
        })
        .catch((error) => {
          this.transitionUrls = { primaryUrl: null, secondaryUrl: null };
          NotificationService.alert({
            type: NOTIFICATION_TYPES.ERROR,
            title: 'Error',
            message: 'Error getting transition URLs!',
            okTitle: 'Ok',
            error
          });
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },

    copyUrl(url) {
      navigator.clipboard.writeText(url).then(() => {
        NotificationService.alert({
          type: NOTIFICATION_TYPES.SUCCESS,
          title: 'Success',
          message: 'Transition URL copied to clipboard!',
          okTitle: 'Ok',
        });
      });
    },

    closeUrlDialog() {
      this.urlDialog = false;
      this.transitionUrls = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.mobile {
  color: var(--color-text-dark);
}

.prepend {
  padding-bottom: 10px;
  border-bottom: 1px solid var(--secondary-color);
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.search-input {
  ::v-deep {
    .v-input__icon--clear {
      height: 16px;
    }

    .fa-times-circle {
      font-size: 16px;
      margin-right: 8px;
    }
  }
}

.actions {
  padding: 2px 4px;
  line-height: 16px;

  &[aria-expanded='true'] {
    background-color: var(--secondary-color);
    border-radius: 4px;

    ::v-deep .v-icon {
      color: var(--primary-color);
    }
  }
}

.menu {
  background-color: var(--color-white);
  min-width: 120px;

  ::v-deep {
    .v-list-item {
      min-height: 24px !important;
      padding: 0;
    }
  }
}

.menu-item-wrapper {
  padding-left: 10px;
  color: var(--color-text) !important;
  font-size: 14px !important;
  font-weight: 700;
  margin-bottom: 0;
  padding: 8px 12px;
  margin: 0 4px;

  ::v-deep {
    .v-icon {
      font-weight: 700;
      color: var(--color-text) !important;
      //margin-right: 8px;
      margin-top: -2px;
    }
  }

  &:hover {
    color: var(--primary-color) !important;
    background-color: var(--input-bg-color);
    text-decoration: underline;

    ::v-deep .v-icon {
      color: var(--primary-color) !important;
    }
  }

  .label {
    padding-left: 8px;
  }
}

.sheet {
  background-color: var(--color-white);

  ::v-deep {
    .v-list-item {
      min-height: 24px !important;
      padding: 0;
    }
  }

  .menu-item-wrapper {
    font-size: 16px !important;
    padding: 12px;
  }

  .bottom-spacer {
    height: 25px;
  }
}

.status-col {
  max-width: 275px;
}

.o-status-col {
  max-width: 175px;
}

.action-column {
  text-align: end !important;
}

button.theme--light.v-btn--has-bg.batch-activator {
  background-color: var(--primary-color);
  color: var(--color-white);
  padding: 22px 20px;
  margin-right: 5px;
  text-transform: none;
  font-size: 16px;
}

.edit-client-link {
  color: var(--color-text);
  font-weight: 700;

  &:hover {
    color: var(--primary-color);
  }
}

.flex-content {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  position: relative;

  .actions-container {
    position: absolute;
    top: 0;
    right: 0;
    line-height: normal;
  }

  .flex-item {
    padding: 5px;
    line-height: 24px;
    font-size: 16px;

    .edit-client-link {
      padding-right: 30px;
      display: inline-block;
      line-height: normal;
    }

    .mobile-label {
      font-weight: 400;
      margin-right: 10px;
    }
  }
}

.search {
  margin-right: 12px;
}

.divider {
  height: 1px;
  margin: 8px;
  background-color: var(--secondary-color);
}

.client-table {
  font-weight: 500;
  color: var(--color-text);

  .disposition-switch {
    ::v-deep label {
      font-size: 14px !important;
    }
  }

  .status-pill {
    padding: 4px 15px;
    border-radius: 30px;
    line-height: 24px;
    font-size: 14px;

    &.Draft,
    &.Ready {
      background-color: hsla(0, 0%, 72.7%, 0.16);
      color: var(--label-text);
    }

    &.Sent {
      background-color: rgba(30, 59, 112, 0.15);
      color: var(--primary-color);
    }

    &.WaitingSignature {
      background-color: rgba(252, 180, 0, 0.25);
      color: var(--primary-color);
    }

    &.WaitingReview {
      display: inline-block;
      background-color: transparent;
      color: var(--color-text);
      font-weight: 700;

      &:hover {
        background-color: var(--input-bg-color);
        text-decoration: underline;
        color: var(--primary-color);
      }
    }

    &.DownloadDocuments {
      display: inline-block;
      background-color: transparent;
      color: var(--color-text);
      font-weight: 700;

      &:hover {
        background-color: var(--input-bg-color);
        text-decoration: underline;
        color: var(--primary-color);
      }
    }

    &.ClientComplete,
    &.Signed {
      background-color: rgba(52, 182, 56, 0.25);
      color: var(--primary-color);
    }

    &.Rejected,
    &.Archived {
      background-color: rgba(232, 44, 44, 0.25);
      color: var(--primary-color);
    }
  }

  .filter-action-row {
    padding-bottom: 12px;
  }

  .weight-300 {
    font-weight: 300;
  }

  .font-fam-ui-serif {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }

  .edit-client-link.disabled {
    text-decoration: none;
    color: var(--color-text);

    &:hover {
      color: var(--color-text);
      cursor: text;
    }
  }
}

@media screen and (max-width: 769px) {
  .filter-action-row {
    flex-direction: column;

    .status-col,
    .o-status-col {
      max-width: 100%;
      padding: 4px 12px;
    }

    .button-area {
      justify-content: flex-start;
    }
  }
}

.client-details {
  border: solid 1px var(--color-light-grey);
  background-color: var(--input-bg-color);
  margin-bottom: 2px;
}

.expanded-container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 3px !important;
}

.expanded-header {
  border-radius: 5px 0 0 5px;
  border-spacing: 0;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  color: white;
  background-color: var(--primary-color) !important;
  padding-bottom: 0 !important;
}

.filter-action-row {
  padding-bottom: 12px;
}

.household-container {
  width: 100%;
  overflow: auto !important;

  &::deep {
    overflow: auto !important;
  }
}

.prepend {
  padding-bottom: 10px;
  border-bottom: 1px solid var(--secondary-color);
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.responsive-table::v-deep .clients-table {
  width: 95%;
  margin: 0 auto;

  thead {
    border-spacing: 0;
    background-color: var(--primary-color) !important;
    padding-bottom: 0 !important;
  }

  tbody {
    &:before {
      content: '-';
      display: block;
      line-height: 0em;
      color: transparent;
    }

    td {
      padding: 6px !important;
    }
  }
}

.search {
  margin-right: 12px;
}

.search-input {
  ::v-deep {
    .v-input__icon--clear {
      height: 16px;
    }

    .fa-times-circle {
      font-size: 16px;
      margin-right: 8px;
    }
  }
}

.status-pill {
  padding: 4px 15px;
  border-radius: 30px;
  line-height: 24px;
  font-size: 14px;
  width: 160px;
  text-align: center;
  display: inline-block;
  font-weight: 450;

  &.WaitingStatement {
    background-color: rgba(252, 180, 0, 0.25);
    color: var(--primary-color);
  }

  &.StatementUploaded {
    background-color: rgba(52, 182, 56, 0.25);
    color: var(--primary-color);
  }

  &.NonSolicitPending {
    background-color: rgba(173, 216, 230, 0.25);
    color: var(--primary-color);
  }

  &.Draft {
    background-color: rgba(30, 59, 112, 0.15);
    color: var(--primary-color);
  }
  &.Transitioned {
    background-color: rgba(52, 182, 56, 0.25);
    color: var(--primary-color);
  }
}

button.theme--light.v-btn--has-bg.batch-activator {
  background-color: var(--primary-color);
  color: var(--color-white);
  padding: 22px 20px;
  margin-right: 5px;
  text-transform: none;
  font-size: 16px;
}

.sms-template {
  ::v-deep .v-text-field__slot {
    padding: 16px;
  }
}

.instructions {
  margin-bottom: 10px;
  font-size: 0.9rem;
  color: #333;

  p {
    margin: 0 0 5px;
  }

  strong {
    color: var(--primary-color);
  }
}

.url-item {
  background-color: var(--input-bg-color);
  padding: 12px;
  border-radius: 4px;

  .url-label {
    font-weight: 700;
    margin-bottom: 8px;
    color: var(--color-text);
  }

  .url-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    word-break: break-all;

    .url-text {
      flex: 1;
      padding-right: 12px;
    }
  }
}

.no-urls {
  text-align: center;
  padding: 20px;
  color: var(--color-text);
  font-weight: 500;
}
</style>
